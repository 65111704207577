<template>
	<ZyroButton
		class="toggle-control"
		:class="{ 'toggle-control--enabled': isStyleEnabled }"
		border-radius="0"
		:icon="toggleValue"
		@click="toggleStyle"
	/>
</template>

<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

const DEFAULT_TOGGLE_VALUE = 'none';

export default {
	name: 'StyleToggleControl',
	props: {
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		toggleValue: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(['website']),
		isStyleEnabled() {
			return Boolean(this.website.styles[this.element][this.property] !== DEFAULT_TOGGLE_VALUE);
		},
	},
	methods: {
		...mapMutations(['setStyleProperty']),
		toggleStyle() {
			if (!this.isStyleEnabled || this.website.styles[this.element][this.property] === undefined) {
				this.setStylePropertyValue(this.toggleValue);

				return;
			}

			this.setStylePropertyValue(DEFAULT_TOGGLE_VALUE);
		},
		setStylePropertyValue(value) {
			this.setStyleProperty({
				element: this.element,
				property: this.property,
				value,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.toggle-control {
	width: 36px;
	height: 36px;
	cursor: pointer;
	border: 0;

	&:hover {
		color: $dark;
		background-color: $grey-200;
	}

	&:focus {
		color: $dark;
	}

	&--enabled {
		background-color: $grey-200;
	}
}
</style>
